import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import screenfull from 'screenfull';
import screenfull from 'screenfull';

@Directive({
  selector: '[maximize]',
  exportAs: 'maximize',
  standalone: true,
})
export class MaximizeDirective {
  private isMaximized = new BehaviorSubject<boolean>(false);
  isMaximized$ = this.isMaximized.pipe();
  reportSelected = false;

  constructor(private el: ElementRef) {}

  toggle() {
    this.isMaximized?.getValue() ? this.minimize() : this.maximize();
  }

  maximize() {
    if (this.el) {
      this.isMaximized.next(true);
      if (screenfull.isEnabled) {
        screenfull.request(this.el.nativeElement);
      }
    }
  }

  minimize() {
    if (this.el) {
      this.isMaximized.next(false);
      if (screenfull.isEnabled) {
        screenfull.exit();
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: any) {
    if (event.key === 'f') {
      this.toggle();
    }
  }
}
