<div class="min-h-0 h-screen overflow-hidden">
  <nav
    class="flex items-center sticky w-full left-0 top-0 bg-primary text-slate-50 h-[35px] z-10 px-2"
  >
    <div class="flex items-center justify-center">
      <!-- <button type="button" (click)="goBack()">
        <span class="material-symbols-outlined text-lg"> arrow_back_ios </span>
      </button> -->
    </div>
    <div class="flex-grow"></div>
    <h4>
      {{ (activeWorkspace$ | async)?.displayName }}&nbsp;&nbsp; - &nbsp;&nbsp;{{
        (activeReport$ | async)?.name
      }}
    </h4>
    <div class="flex-grow"></div>
    <div class="flex flex-row items-center justify-center gap-x-2">
      <div class="dropdown dropdown-hover dropdown-bottom dropdown-end">
        <div tabindex="0" role="button" class="btn btn-ghost">
          <span class="material-symbols-outlined"> display_settings </span>
        </div>
        <ul
          tabindex="0"
          class="dropdown-content menu bg-primary rounded-box z-[1] w-52 p-2 shadow"
        >
          @for (
            displayOption of this.displayOptions;
            track displayOption.name
          ) {
            <li>
              <button
                (click)="changeDisplayOption(displayOption)"
                class="p-1.5 xl:p-2.5 flex items-center justify-start rounded-md xl:px-4 duration-300 cursor-pointer hover:bg-opacity-5 hover:bg-white text-white"
              >
                <span class="material-symbols-outlined">
                  {{ displayOption.icon }}
                </span>
                <span class="text-sm ml-4 text-gray-200">{{
                  displayOption.name
                }}</span>
              </button>
            </li>
          }
        </ul>
      </div>
      <button class="flex items-center" (click)="this.maximize.maximize()">
        <span class="material-symbols-outlined"> fullscreen </span>
      </button>
    </div>
  </nav>
  <div
    class="h-[calc(100vh-40px)] min-h-0 flexf overflow-y-auto items-center justify-center text-slate-50 z-[-1]"
  >
    <!-- <div *ngIf="!this.isEmbedded" class="w-full h-full flex flex-col items-center justify-center">
  <span class="text-xs text-slate-300 leading-6 tracking-wider">Loading...</span>
  <span class="text-slate-300 loading loading-ring loading-lg">
  </span>
</div> -->
    <div
      maximize
      #maximize="maximize"
      class="h-full relative overflow-x-hidden"
    >
      <!-- <div class="h-fit flex items-center justify-start absolute sm:top-4 top-1 left-1 z-10">
      <div
        class="text-xs sm:col-span-2 flex items-center justify-center bg-primary bg-opacity-50 hover:bg-opacity-25 rounded-lg p-1 w-fit hover:cursor-pointer"
        (click)="goBack()">
        <mat-icon class="text-slate-300" style="color:white;">navigate_before</mat-icon>
      </div>
      <div (click)="this.maximize.toggle()"  class="text-xs sm:col-span-2 flex items-center justify-center bg-primary bg-opacity-50 hover:bg-opacity-25 rounded-lg p-1 w-fit hover:cursor-pointer">
        <mat-icon class="text-slate-300" style="color:white;">fullscreen</mat-icon>
      </div>
  
    </div> -->
      <div class="w-full h-full" #embedcontainer data-testid="embedcontainer">
        <!-- report is bootstrapped in this div called 'embedcontainer' -->
      </div>
    </div>
    <!-- <app-error *ngIf="reportError"></app-error> -->
  </div>
</div>
